<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="d-flex">
            <div class="card card-body">
              <div class="row">
                <div class="search-wrapper panel-heading col-sm-4 mb-5">
                  <input class="form-control" type="text" v-model="searchQuery" placeholder="Procurar" />
                </div>
              </div>
              <div class="table-responsive table-card">
                <table class="table align-middle table-nowrap"
                       id="customerTable">
                  <thead class="table-light text-muted">
                  <tr>
                    <th class="sort" data-sort="currency_name" scope="col">ID</th>
                    <th class="sort" data-sort="current_value" scope="col">
                      Nome
                    </th>
                    <th class="sort" data-sort="pairs" scope="col">Email</th>
                    <th class="sort" data-sort="low" scope="col">CPF</th>
                    <th class="sort" data-sort="market_cap" scope="col">
                      Cidade
                    </th>
                    <th scope="col">Ação</th>
                  </tr>
                  </thead>
                  <!--end thead-->
                  <tbody class="list form-check-all">
                  <tr v-for="(data, index) of resultQuery" :key="index">
                    <td class="id">
                      {{ data.id }}
                    </td>
                    <td>{{ data.Nome }}</td>
                    <td class="pairs">{{ data.Email }}</td>

                    <td class="low">{{ data.CPF }}</td>
                    <td class="market_cap">{{ data.Cidade }}</td>
                    <td>
                      <span>
                        <router-link class="text-reset text-decoration-underline"
                                     :to="{name : 'Paciente', params:  {id: data.id }  }">
                          Detalhes
                        </router-link>
                      </span>
                    </td>
                  </tr>
                  </tbody>
                  <!--end tbody-->
                </table>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <div class="pagination-wrap hstack gap-2">
                  <a class="page-item pagination-prev disabled" href="#" v-if="page != 1" @click="page--">
                    Anterior
                  </a>
                  <ul class="pagination listjs-pagination mb-0">
                    <li :class="{
                              active: pageNumber == page,
                              disabled: pageNumber == '...',
                            }" v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)" :key="index"
                        @click="page = pageNumber">
                      <a class="page" href="#">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a class="page-item pagination-next" href="#" @click="page++" v-if="page < pages.length">
                    Próximo
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import axios from "axios";

export default {
  name: "Pacientes",
  page: {
    title: "Pacientes",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ],
  },
  data() {
    return {
      title: "Pacientes",
      items: [
        {
          text: "paciente",
          href: "/",
        },
        {
          text: "pacientes",
          active: true,
        },
      ],
      data:[],
      page: 1,
      perPage: 15,
      pages: [],
      searchQuery:'',
    };
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.data);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return  (
              data.Nome.toLowerCase().includes(search)||
              data.Email.toLowerCase().includes(search) ||
              data.CPF.toLowerCase().includes(search)
          );
        });
      } else {
        return this.displayedPosts;
      }
    },
  },
  mounted() {

  },
  watch: {
    posts() {
      this.setPages();
    },
  },
  created() {
    this.LoadPacientes();
    //this.setPages();

  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  methods: {
    setPages() {
      let numberOfPages = Math.ceil(this.data.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
    async LoadPacientes() {

      const result = await axios.post(process.env.VUE_APP_API +'/v1/listarpacientes', {})
      //console.log(result.data);

      this.data = result.data;

      //this.paginate(this.data);
      this.setPages();
      //this.displayedPosts();

    }
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>